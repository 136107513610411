@charset "UTF-8";
/* COLORS */
/* font size */
/* font */
/* effect */
/* button */
/* footer */
#panel-footer {
  background-color: #6A6A63;
  line-height: 25px;
  /*view more link*/
  /*why us*/ }
  #panel-footer .container {
    padding-top: 60px;
    padding-bottom: 30px; }
  #panel-footer h2 {
    font-size: 1em;
    color: #fff;
    font-weight: 700;
    margin-bottom: 25px; }
  #panel-footer .content {
    color: #fff; }
  #panel-footer ul.menu {
    margin-bottom: 0;
    padding-left: 0; }
    #panel-footer ul.menu li {
      margin-left: 0;
      margin-bottom: 10px;
      padding-top: 0;
      padding-right: 0;
      list-style: none; }
  #panel-footer a {
    color: #fff; }
    #panel-footer a:hover, #panel-footer a:focus {
      color: #e52629; }
  #panel-footer .btn-view-more {
    border-color: #6A6A63;
    background-size: 1px 100px; }
    #panel-footer .btn-view-more:hover, #panel-footer .btn-view-more:focus, #panel-footer .btn-view-more:active {
      border-color: #fff;
      background-color: #fff;
      -webkit-transition: background-color 1000ms linear;
      -moz-transition: background-color 1000ms linear;
      -ms-transition: background-color 1000ms linear;
      -o-transition: background-color 1000ms linear;
      transition: background-color 1000ms linear; }
  #panel-footer .ui-accordion-header,
  #panel-footer .ui-accordion-content {
    border-color: #333; }
    #panel-footer .ui-accordion-header:hover, #panel-footer .ui-accordion-header:focus,
    #panel-footer .ui-accordion-content:hover,
    #panel-footer .ui-accordion-content:focus {
      border-color: #6A6A63; }
  #panel-footer .ui-accordion-header-active,
  #panel-footer .ui-accordion-content-active {
    border-color: #6A6A63; }

.contact-info {
  margin-top: 20px;
  margin-bottom: 0; }
  .contact-info i {
    margin-right: 7px;
    min-width: 17px; }
  .contact-info p {
    margin-bottom: 10px; }
    .contact-info p:last-child {
      margin-bottom: 0; }
  .contact-info a {
    color: #e52629 !important; }
    .contact-info a:hover, .contact-info a:focus {
      color: #c1171a !important; }
  #panel-footer .contact-info a {
    color: #fff !important; }
    #panel-footer .contact-info a:hover, #panel-footer .contact-info a:focus {
      color: #e52629 !important; }

/* newsletter form */
#newsletter-subscribe-form {
  margin-top: 25px;
  position: relative; }
  #newsletter-subscribe-form .form-item {
    margin-top: 0;
    margin-bottom: 0;
    background: #fff; }
    #newsletter-subscribe-form .form-item .form-text {
      border: none;
      background: none;
      width: 100%;
      padding: 10px 15px;
      font-size: 0.857em; }

#subscribe {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  opacity: 0.6;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in; }
  #subscribe .form-submit {
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    text-indent: -999em;
    padding: 0;
    position: relative;
    z-index: 5; }
  #subscribe:before {
    position: absolute;
    right: 11px;
    top: 0;
    z-index: 2;
    background-image: url("../images/sprite.png");
    background-repeat: no-repeat;
    background-position: 0 -25px;
    width: 16px;
    height: 37px;
    content: ""; }
  #subscribe:hover, #subscribe:focus {
    color: #e52629;
    opacity: 1; }

.logged-in #subscribe {
  width: 100%;
  background-color: #fff;
  left: 0; }
  .logged-in #subscribe .form-submit {
    color: #333;
    text-indent: 0;
    padding-left: 10px;
    text-align: left; }
    .logged-in #subscribe .form-submit:hover, .logged-in #subscribe .form-submit:focus {
      color: #e52629; }
  .logged-in #subscribe:before {
    top: 2px; }

#newsletter-error {
  color: #e52629;
  padding-top: 10px; }

/* copyright */
#footer {
  background-color: #6A6A63; }
  #footer .container {
    padding-top: 0; }
  #footer .region-footer {
    padding-top: 30px;
    border-top: 1px solid #fff; }
  #footer .content {
    color: #fff; }
  #footer a:hover, #footer a:focus {
    color: #e52629; }
  #footer .block {
    width: 50%;
    float: left; }
    #footer .block.footer-logos {
      background: url("../images/footer-logos.png") right top no-repeat;
      height: 40px; }
      #footer .block.footer-logos .content {
        display: none; }

/*panel-third*/
#panel-third .container {
  padding-top: 60px;
  padding-bottom: 60px; }

#panel-third h2 {
  margin-bottom: 30px; }

#panel-third #newsletter-subscribe-form .form-text {
  background-color: #6A6A63;
  color: #fff; }

/*who we are custom block*/
.entity-bean {
  /*link view more*/ }
  .entity-bean .field {
    margin-bottom: 25px; }
    .entity-bean .field:last-child {
      margin-bottom: 0; }
  .entity-bean p:last-child {
    margin-bottom: 0; }
  .entity-bean .field-type-link-field a {
    display: inline-block;
    border: 2px solid #2D2D2D;
    padding: 15px 20px;
    text-transform: uppercase;
    color: #333;
    position: relative;
    z-index: 2;
    line-height: 1em;
    border-radius: 0;
    -webkit-border-radius: 0;
    font-size: 0.857em;
    font-weight: 600; }
    .entity-bean .field-type-link-field a:after {
      height: 100%;
      left: 0;
      top: 0;
      width: 0;
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
    .entity-bean .field-type-link-field a:hover, .entity-bean .field-type-link-field a:focus, .entity-bean .field-type-link-field a:active {
      color: #fff; }
      .entity-bean .field-type-link-field a:hover:after, .entity-bean .field-type-link-field a:focus:after, .entity-bean .field-type-link-field a:active:after {
        width: 100%;
        background: #2D2D2D; }

/*end who we are*/
/*ui-accordion*/
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-radius: 0; }

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border-color: #ddd; }

.ui-accordion {
  font-family: 'Proxima N W01 Reg', sans-serif;
  font-size: 1em;
  /*icon*/ }
  .ui-accordion p:last-child {
    margin-bottom: 10px; }
  .ui-accordion .ui-accordion-header {
    border-radius: 0;
    -webkit-border-radius: 0;
    background: none;
    padding: 18px 20px;
    text-transform: uppercase;
    border-color: #ddd;
    position: relative;
    margin-top: 10px;
    font-size: 0.857em;
    font-weight: 700;
    /*icon*/ }
    .ui-accordion .ui-accordion-header a {
      color: #333; }
    .ui-accordion .ui-accordion-header:hover, .ui-accordion .ui-accordion-header:focus {
      border-color: #333;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }
    .ui-accordion .ui-accordion-header .ui-icon {
      text-indent: 0;
      right: 14px;
      background: none;
      left: auto; }
  .ui-accordion .ui-accordion-content {
    border-bottom-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    padding: 10px 20px 20px;
    background: none;
    border-color: #ddd;
    color: #6A6A63; }
  .ui-accordion .ui-accordion-header-active,
  .ui-accordion .ui-accordion-content-active {
    border-color: #333; }
  .ui-accordion .ui-accordion-header-active {
    border-bottom: none;
    padding-bottom: 8px; }
  .ui-accordion .ui-accordion-content-active {
    padding-top: 8px; }
  .ui-accordion .ui-state-default .ui-icon:before {
    content: "+";
    position: absolute;
    top: -12px;
    right: 0;
    width: 16px;
    height: 16px;
    z-index: 2;
    font-size: 2.57em;
    font-weight: normal; }
  .ui-accordion .ui-state-active .ui-icon:before {
    content: "-";
    position: absolute;
    top: -10px;
    right: 0;
    width: 16px;
    height: 16px;
    z-index: 2;
    font-size: 2.57em;
    font-weight: normal; }
  .ui-accordion .ui-accordion-content {
    height: auto !important; }

/*end ui-accordion*/
/* views bootstrap */
.views-bootstrap-accordion-plugin-style .panel {
  border-radius: 0;
  -webkit-border-radius: 0;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  margin-top: 10px !important;
  background: none; }

.views-bootstrap-accordion-plugin-style .panel-heading {
  border-radius: 0;
  -webkit-border-radius: 0;
  background: none;
  text-transform: uppercase;
  border-color: #ddd;
  position: relative;
  padding: 0;
  font-size: 0.857em;
  font-weight: 700; }
  .views-bootstrap-accordion-plugin-style .panel-heading a {
    color: #333;
    display: block;
    padding: 18px 40px 18px 20px;
    border: 1px solid #333;
    position: relative;
    border-bottom: none;
    font-size: 0.857em;
    font-weight: 600; }
    .views-bootstrap-accordion-plugin-style .panel-heading a:before {
      content: "-";
      position: absolute;
      top: 2px;
      right: 20px;
      width: 16px;
      height: 16px;
      z-index: 2;
      font-size: 2.57em;
      font-weight: normal; }
  .views-bootstrap-accordion-plugin-style .panel-heading a.collapsed {
    border-color: #ddd;
    border-bottom: 1px solid #ddd; }
    .views-bootstrap-accordion-plugin-style .panel-heading a.collapsed:before {
      content: "+";
      position: absolute;
      top: 9px;
      right: 20px;
      width: 16px;
      height: 16px;
      z-index: 2;
      font-weight: normal; }
    .views-bootstrap-accordion-plugin-style .panel-heading a.collapsed:hover, .views-bootstrap-accordion-plugin-style .panel-heading a.collapsed:focus {
      border-color: #333;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }

.views-bootstrap-accordion-plugin-style .panel-body {
  border: 1px solid #333;
  border-top: none !important;
  padding: 0 50px 20px 20px; }
  .views-bootstrap-accordion-plugin-style .panel-body p:last-child {
    margin-bottom: 0; }

/*view happy client*/
.testimonials {
  text-align: center;
  background-color: #333;
  padding: 40px 30px;
  /*flex control nav*/
  /*flex direction nav*/ }
  .testimonials .flexslider {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    margin-bottom: 0; }
  .testimonials .views-field-picture {
    width: 105px;
    margin: 0 auto 15px; }
    .testimonials .views-field-picture img {
      border-radius: 50%;
      -webkit-border-radius: 50%; }
  .testimonials .views-field-name {
    color: #e52629;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px; }
    .testimonials .views-field-name a:hover, .testimonials .views-field-name a:focus {
      color: #fff; }
  .testimonials .views-field-field-occupation {
    color: #999;
    font-weight: 600;
    margin-bottom: 15px; }
    .testimonials .views-field-field-occupation a {
      color: #999; }
      .testimonials .views-field-field-occupation a:hover, .testimonials .views-field-field-occupation a:focus {
        color: #e52629; }
  .testimonials .views-field-body {
    color: #999;
    font-weight: 600;
    position: relative;
    padding-top: 25px; }
    .testimonials .views-field-body:before {
      position: absolute;
      content: "";
      background-image: url("../images/sprite.png");
      background-repeat: no-repeat;
      background-position: 0 -60px;
      width: 100px;
      height: 15px;
      z-index: 3;
      top: 0;
      left: 50%;
      margin-left: -50px; }
  .testimonials .flex-control-nav {
    display: none; }
  .testimonials .flex-direction-nav a {
    text-shadow: none;
    display: block;
    color: #6A6A63;
    opacity: 1;
    top: 40px;
    margin-top: 0;
    text-indent: 999em; }
    .testimonials .flex-direction-nav a:before {
      font-size: 1.714em; }
    .testimonials .flex-direction-nav a:hover, .testimonials .flex-direction-nav a:focus {
      color: #ddd; }
  .testimonials .flex-direction-nav .flex-prev {
    left: 60px;
    background-image: url("../images/sprite.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 16px;
    height: 25px;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg); }
    .testimonials .flex-direction-nav .flex-prev:before {
      content: none; }
  .testimonials .flex-direction-nav .flex-next {
    right: 60px;
    background-image: url("../images/sprite.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 16px;
    height: 25px; }
    .testimonials .flex-direction-nav .flex-next:before {
      content: none; }
  .testimonials p {
    margin-bottom: 10px; }

/*end view happy client*/
/*panel-second*/
#panel-second h2 {
  margin-bottom: 30px; }

#panel-second .container {
  padding-bottom: 0; }

#panel-second .region-panel-second {
  border-bottom: 1px solid #ddd;
  padding-bottom: 50px; }

/*agent block*/
#featured .agent-type {
  margin-bottom: 50px; }
  #featured .agent-type .grid-inner {
    border: none; }

.agent-type, .profile {
  /*agent picture*/
  /*agent name*/
  /*field group*/
  /*icon*/
  /*function add icon to views-field*/ }
  .agent-type .grid, .profile .grid {
    padding-left: 15px;
    padding-right: 15px; }
  .agent-type .views-row, .profile .views-row {
    margin-left: -15px;
    margin-right: -15px; }
  .agent-type .grid-inner, .profile .grid-inner {
    padding: 20px;
    border: 1px solid #ddd;
    min-height: 220px;
    background-color: #fff; }
  .agent-type .views-field, .profile .views-field {
    margin-bottom: 10px; }
    .agent-type .views-field:last-child, .profile .views-field:last-child {
      margin-bottom: 0; }
  .agent-type .views-field-field-office, .agent-type .field-name-field-office, .profile .views-field-field-office, .profile .field-name-field-office {
    color: #999; }
  .agent-type .views-field-picture, .profile .views-field-picture {
    float: left;
    margin-right: 20px;
    margin-bottom: 0; }
  .agent-type .views-field-field-full-name, .agent-type .field-name-field-full-name, .profile .views-field-field-full-name, .profile .field-name-field-full-name {
    color: #333;
    font-weight: 700;
    text-transform: uppercase; }
    .agent-type .views-field-field-full-name a, .agent-type .field-name-field-full-name a, .profile .views-field-field-full-name a, .profile .field-name-field-full-name a {
      color: #333; }
      .agent-type .views-field-field-full-name a:hover, .agent-type .views-field-field-full-name a:focus, .agent-type .field-name-field-full-name a:hover, .agent-type .field-name-field-full-name a:focus, .profile .views-field-field-full-name a:hover, .profile .views-field-field-full-name a:focus, .profile .field-name-field-full-name a:hover, .profile .field-name-field-full-name a:focus {
        color: #e52629; }
  .agent-type .agent-detail, .profile .agent-detail {
    overflow: hidden; }
  .agent-type .views-label, .agent-type .field-label, .profile .views-label, .profile .field-label {
    float: left;
    font-weight: normal; }
  .agent-type .views-label, .profile .views-label {
    margin-right: 7px; }
  .agent-type p:last-child, .profile p:last-child {
    margin-bottom: 0; }
  .agent-type .views-field-field-office, .agent-type .field-name-field-office, .profile .views-field-field-office, .profile .field-name-field-office {
    margin-bottom: 5px !important; }
    .agent-type .views-field-field-office a, .agent-type .field-name-field-office a, .profile .views-field-field-office a, .profile .field-name-field-office a {
      color: #999; }
      .agent-type .views-field-field-office a:hover, .agent-type .views-field-field-office a:focus, .agent-type .field-name-field-office a:hover, .agent-type .field-name-field-office a:focus, .profile .views-field-field-office a:hover, .profile .views-field-field-office a:focus, .profile .field-name-field-office a:hover, .profile .field-name-field-office a:focus {
        color: #333; }
  .agent-type .views-field-field-phone,
  .agent-type .field-phone-style, .agent-type .field-name-field-phone, .profile .views-field-field-phone,
  .profile .field-phone-style, .profile .field-name-field-phone {
    position: relative;
    padding-left: 22px; }
    .agent-type .views-field-field-phone:before,
    .agent-type .field-phone-style:before, .agent-type .field-name-field-phone:before, .profile .views-field-field-phone:before,
    .profile .field-phone-style:before, .profile .field-name-field-phone:before {
      position: absolute;
      left: 1px;
      top: 0;
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .agent-type .views-field-field-email,
  .agent-type .field-email-style, .agent-type .field-name-field-email, .profile .views-field-field-email,
  .profile .field-email-style, .profile .field-name-field-email {
    position: relative;
    padding-left: 22px; }
    .agent-type .views-field-field-email:before,
    .agent-type .field-email-style:before, .agent-type .field-name-field-email:before, .profile .views-field-field-email:before,
    .profile .field-email-style:before, .profile .field-name-field-email:before {
      position: absolute;
      left: 1px;
      top: 0;
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .agent-type .views-field-field-mobile,
  .agent-type .field-mobile-style, .agent-type .field-name-field-mobile, .profile .views-field-field-mobile,
  .profile .field-mobile-style, .profile .field-name-field-mobile {
    position: relative;
    padding-left: 22px; }
    .agent-type .views-field-field-mobile:before,
    .agent-type .field-mobile-style:before, .agent-type .field-name-field-mobile:before, .profile .views-field-field-mobile:before,
    .profile .field-mobile-style:before, .profile .field-name-field-mobile:before {
      position: absolute;
      left: 1px;
      top: 0;
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .agent-type .views-field-field-mobile:before,
    .agent-type .field-mobile-style:before, .agent-type .field-name-field-mobile:before, .profile .views-field-field-mobile:before,
    .profile .field-mobile-style:before, .profile .field-name-field-mobile:before {
      font-size: 1.428em;
      top: -5px; }
  .agent-type .views-field-field-fax,
  .agent-type .field-fax-style, .agent-type .field-name-field-fax, .profile .views-field-field-fax,
  .profile .field-fax-style, .profile .field-name-field-fax {
    position: relative;
    padding-left: 22px; }
    .agent-type .views-field-field-fax:before,
    .agent-type .field-fax-style:before, .agent-type .field-name-field-fax:before, .profile .views-field-field-fax:before,
    .profile .field-fax-style:before, .profile .field-name-field-fax:before {
      position: absolute;
      left: 1px;
      top: 0;
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

/*end agent block*/
/*slideshow flexslider*/
.slideshow .flexslider {
  margin-bottom: 0;
  box-shadow: none;
  background: none;
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0; }
  .slideshow .flexslider:hover .flex-direction-nav, .slideshow .flexslider:focus .flex-direction-nav {
    visibility: visible; }

.slideshow .flex-direction-nav {
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in; }
  .slideshow .flex-direction-nav a {
    background: #2D2D2D;
    color: #ddd;
    cursor: pointer;
    display: block;
    font-size: 2.57em;
    height: 100px;
    line-height: 100px;
    margin-top: -50px;
    opacity: 0.6;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    width: 40px;
    z-index: 5; }
    .slideshow .flex-direction-nav a:hover, .slideshow .flex-direction-nav a:focus {
      background-color: #e52629;
      opacity: 1;
      color: #fff; }
    .slideshow .flex-direction-nav a:before {
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block; }
  .slideshow .flex-direction-nav .flex-prev {
    left: 0; }
    .slideshow .flex-direction-nav .flex-prev:before {
      content: "\f104"; }
  .slideshow .flex-direction-nav .flex-next {
    right: 0; }
    .slideshow .flex-direction-nav .flex-next:before {
      content: "\f105"; }

.slideshow .flex-control-nav {
  z-index: 10;
  bottom: 25px; }
  .slideshow .flex-control-nav a {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 12px;
    height: 12px; }
    .slideshow .flex-control-nav a:hover, .slideshow .flex-control-nav a:focus {
      background-color: #fff; }
  .slideshow .flex-control-nav .flex-active {
    background-color: #fff;
    transform: scale(1.2);
    -webkit-transform: scale(1.2); }

/*form login*/
div.modal-forms-modal-content {
  width: 400px !important;
  height: auto !important;
  font-size: 1em;
  font-family: 'Proxima N W01 Reg', sans-serif;
  /* login */ }
  div.modal-forms-modal-content .popups-container {
    border-radius: 0;
    -webkit-border-radius: 0; }
  div.modal-forms-modal-content .modal-header {
    font-size: 1em;
    padding: 10px 20px;
    background-color: #e52629;
    position: relative;
    font-family: 'Proxima N W01 Reg', sans-serif; }
    div.modal-forms-modal-content .modal-header:before {
      content: "\f00d";
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 12px;
      right: 10px;
      z-index: 5;
      font-size: 1.285em;
      color: #fff; }
  div.modal-forms-modal-content .modal-title {
    color: #fff;
    text-transform: uppercase;
    font-weight: normal; }
  div.modal-forms-modal-content .close {
    background: rgba(229, 38, 41, 0.9);
    position: relative;
    z-index: 6;
    width: 20px;
    height: 20px;
    margin-top: 0; }
    div.modal-forms-modal-content .close:hover, div.modal-forms-modal-content .close:focus {
      background: transparent; }
  div.modal-forms-modal-content .modal-content {
    box-shadow: none;
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    padding: 20px;
    width: 100% !important;
    height: 100% !important; }
  div.modal-forms-modal-content form {
    padding: 0 !important; }
    div.modal-forms-modal-content form label {
      display: block;
      color: #6A6A63; }
    div.modal-forms-modal-content form .form-text {
      max-width: inherit !important;
      width: 100%; }
    div.modal-forms-modal-content form .form-item input.error {
      border-width: 1px; }
    div.modal-forms-modal-content form .item-list {
      margin-bottom: 10px; }
      div.modal-forms-modal-content form .item-list ul {
        padding-left: 0; }
      div.modal-forms-modal-content form .item-list li {
        list-style: none;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 0;
        display: inline-block; }
        div.modal-forms-modal-content form .item-list li a {
          color: #6A6A63; }
          div.modal-forms-modal-content form .item-list li a:hover, div.modal-forms-modal-content form .item-list li a:focus {
            color: #e52629; }
    div.modal-forms-modal-content form .description {
      padding-top: 5px; }
    div.modal-forms-modal-content form .form-actions {
      margin-bottom: 0; }
    div.modal-forms-modal-content form .form-submit {
      background: #333; }
      div.modal-forms-modal-content form .form-submit:hover, div.modal-forms-modal-content form .form-submit:focus {
        background: #e52629; }

/*feature slider*/
.featured-properties {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px; }
  .featured-properties .jcarousel-container {
    width: 100%;
    height: 170px;
    padding: 0;
    margin-bottom: 30px; }
  .featured-properties .jcarousel-clip-horizontal {
    width: 100%;
    height: 100%; }
  .featured-properties .jcarousel-item {
    width: 260px;
    height: auto;
    position: relative;
    margin-right: 33px;
    text-align: left;
    background: none;
    border: none;
    float: left;
    margin-left: 0; }
    .featured-properties .jcarousel-item:hover .views-field-type-image, .featured-properties .jcarousel-item:focus .views-field-type-image {
      transform: scale(1.2);
      -webkit-transform: scale(1.2); }
    .featured-properties .jcarousel-item:hover .views-field-body, .featured-properties .jcarousel-item:focus .views-field-body {
      max-height: 500px; }
  .featured-properties .fieldset {
    position: absolute;
    bottom: -10px;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px 20px; }
  .featured-properties .views-field-title {
    text-transform: uppercase;
    margin-bottom: 10px; }
    .featured-properties .views-field-title a {
      color: #fff; }
  .featured-properties .views-field-type-image {
    -webkit-transition: all 1s ease-in;
    -moz-transition: all 1s ease-in;
    -ms-transition: all 1s ease-in;
    -o-transition: all 1s ease-in;
    transition: all 1s ease-in; }
  .featured-properties .views-field-body {
    color: #999;
    overflow: hidden;
    font-size: 0.857em;
    max-height: 0;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in; }

.jcarousel-container .jcarousel-prev,
.jcarousel-container .jcarousel-next {
  display: block;
  background: none;
  font-size: 1.714em;
  border: 2px solid #333;
  width: 40px;
  height: 40px;
  color: #333;
  z-index: 0;
  top: -70px; }
  .jcarousel-container .jcarousel-prev:before,
  .jcarousel-container .jcarousel-next:before {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 15px;
    height: 15px;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 12px; }
  .jcarousel-container .jcarousel-prev:after,
  .jcarousel-container .jcarousel-next:after {
    height: 100%;
    left: 0;
    top: 0;
    width: 0;
    content: "";
    position: absolute;
    z-index: 1;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
  .jcarousel-container .jcarousel-prev:hover, .jcarousel-container .jcarousel-prev:focus, .jcarousel-container .jcarousel-prev:active,
  .jcarousel-container .jcarousel-next:hover,
  .jcarousel-container .jcarousel-next:focus,
  .jcarousel-container .jcarousel-next:active {
    color: #fff; }
    .jcarousel-container .jcarousel-prev:hover:after, .jcarousel-container .jcarousel-prev:focus:after, .jcarousel-container .jcarousel-prev:active:after,
    .jcarousel-container .jcarousel-next:hover:after,
    .jcarousel-container .jcarousel-next:focus:after,
    .jcarousel-container .jcarousel-next:active:after {
      width: 100%;
      color: #fff;
      background: #2D2D2D; }

.jcarousel-container .jcarousel-prev {
  left: auto;
  right: 0;
  left: auto;
  right: 50px; }
  .jcarousel-container .jcarousel-prev:before {
    content: "\f104"; }

.jcarousel-container .jcarousel-next {
  right: auto;
  left: 0;
  left: auto;
  right: 0; }
  .jcarousel-container .jcarousel-next:before {
    content: "\f105"; }

/*region-content-action*/
.region-content-action {
  padding: 10px;
  clear: both;
  background-color: #fff;
  border: 1px solid #ddd;
  overflow: hidden;
  margin-bottom: 30px;
  /*menu*/
  /*form exposed*/ }
  .region-content-action .block-views h2 {
    display: none; }
  .region-content-action .views-exposed-widget {
    display: none; }
  .region-content-action .views-widget-sort-by,
  .region-content-action .views-widget-sort-order,
  .region-content-action .views-submit-button {
    display: block;
    width: auto;
    margin-left: 20px;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 1px; }
  .region-content-action .menu {
    padding-left: 0;
    margin-bottom: 0; }
    .region-content-action .menu li {
      font-size: 1.285em;
      margin-left: 0;
      padding-right: 0;
      padding-top: 0;
      list-style: none;
      float: left;
      margin-right: 10px; }
    .region-content-action .menu a {
      padding: 0;
      color: #333;
      border: 1px solid #ddd;
      display: block;
      line-height: 1em;
      width: 40px;
      height: 39px;
      line-height: 39px;
      text-align: center;
      position: relative;
      z-index: 2; }
      .region-content-action .menu a:after {
        width: 0;
        height: 100%;
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s; }
      .region-content-action .menu a:hover, .region-content-action .menu a:focus {
        color: #fff;
        border-color: #333; }
        .region-content-action .menu a:hover:after, .region-content-action .menu a:focus:after {
          background-color: #333;
          width: 100%; }
    .region-content-action .menu .active {
      color: #fff;
      background-color: #333;
      border-color: #333; }
  .region-content-action .views-exposed-form {
    float: right; }
  .region-content-action label,
  .region-content-action .form-select {
    display: inline-block;
    width: auto; }
  .region-content-action label {
    font-weight: 500;
    color: #333;
    margin-right: 5px; }
  .region-content-action .form-select {
    height: 38px;
    min-width: 100px; }
  .region-content-action .views-submit-button {
    padding-right: 0; }
  .region-content-action .form-submit {
    margin-right: 0;
    height: 38px; }

/*properties-map-page*/
.gmap-popup {
  width: 270px;
  clear: both;
  overflow: hidden;
  padding: 10px 0 10px 5px; }
  .gmap-popup .property-price-group {
    left: 0 !important; }
  .gmap-popup .property-image-group {
    margin-bottom: 20px; }
  .gmap-popup .views-field-field-image {
    margin: 0; }
  .gmap-popup .property-price-group {
    left: 0; }
  .gmap-popup .views-field-title a {
    color: #333;
    font-size: 18px !important; }
  .gmap-popup .property-address-group {
    min-height: 30px !important; }
  .gmap-popup .property-info-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important; }

.gm-style-iw {
  min-height: 300px; }

.gm-style {
  font-family: 'Proxima N W01 Reg', sans-serif; }
  .gm-style .gm-style-iw {
    font-size: 14px; }

/*properties-list-page*/
.properties-list-page {
  color: #6A6A63; }
  .properties-list-page .views-row {
    margin-bottom: 30px;
    background-color: #fff;
    overflow: hidden;
    padding: 20px; }
    .properties-list-page .views-row:last-child {
      margin-bottom: 0; }
  .properties-list-page .views-field-type-image, .properties-list-page .views-field-field-image, .properties-list-page .views-field-field-gallery {
    overflow: hidden; }
    .properties-list-page .views-field-type-image img, .properties-list-page .views-field-field-image img, .properties-list-page .views-field-field-gallery img {
      -webkit-transition: all 0.9s ease-in;
      -moz-transition: all 0.9s ease-in;
      -ms-transition: all 0.9s ease-in;
      -o-transition: all 0.9s ease-in;
      transition: all 0.9s ease-in; }
      .properties-list-page .views-field-type-image img:hover, .properties-list-page .views-field-type-image img:focus, .properties-list-page .views-field-field-image img:hover, .properties-list-page .views-field-field-image img:focus, .properties-list-page .views-field-field-gallery img:hover, .properties-list-page .views-field-field-gallery img:focus {
        transform: scale(1);
        -webkit-transform: scale(1); }
  .properties-list-page .views-field-title {
    font-size: 1.285em;
    margin-bottom: 10px;
    color: #333; }
    .properties-list-page .views-field-title a {
      color: #333; }
      .properties-list-page .views-field-title a:hover, .properties-list-page .views-field-title a:focus {
        color: #e52629; }
  .properties-list-page .property-image-group {
    position: relative;
    float: left;
    margin: -20px 30px -20px -20px; }
    .properties-list-page .property-image-group .property-price-group {
      position: absolute;
      z-index: 10;
      top: 20px;
      width: 100%; }
      .properties-list-page .property-image-group .property-price-group .views-field {
        display: block;
        float: left;
        color: #fff; }
      .properties-list-page .property-image-group .property-price-group .views-field-field-price {
        padding: 10px 20px;
        background-color: #2D2D2D; }
      .properties-list-page .property-image-group .property-price-group .views-field-field-property-status a {
        display: block;
        padding: 10px 20px;
        color: #fff !important;
        background-color: #e52629;
        font-size: 1em !important; }
        .properties-list-page .property-image-group .property-price-group .views-field-field-property-status a:hover, .properties-list-page .property-image-group .property-price-group .views-field-field-property-status a:focus {
          background-color: #333; }
  .properties-list-page .property-address-group {
    margin-bottom: 10px;
    overflow: hidden; }
  .properties-list-page .views-field-field-address .street-block, .properties-list-page .views-field-field-address .locality-block, .properties-list-page .views-field-field-address .country {
    display: block;
    float: left;
    color: #6A6A63;
    margin-right: 3px; }
  .properties-list-page .views-field-field-business-type {
    clear: right; }
    .properties-list-page .views-field-field-business-type .views-label, .properties-list-page .views-field-field-business-type .field-content {
      display: block;
      float: left;
      margin-right: 3px; }
      .properties-list-page .views-field-field-business-type .views-label a, .properties-list-page .views-field-field-business-type .field-content a {
        color: #6A6A63 !important; }
        .properties-list-page .views-field-field-business-type .views-label a:hover, .properties-list-page .views-field-field-business-type .views-label a:focus, .properties-list-page .views-field-field-business-type .field-content a:hover, .properties-list-page .views-field-field-business-type .field-content a:focus {
          color: #e52629 !important; }
  .properties-list-page .views-field-body {
    min-height: 90px; }
  .properties-list-page .property-info-group {
    position: relative;
    border-top: 1px solid #ddd;
    overflow: hidden;
    margin-top: 10px;
    padding-top: 20px; }
    .properties-list-page .property-info-group .views-field {
      display: block;
      float: left;
      margin-right: 15px; }
    .properties-list-page .property-info-group .views-field-field-lot-area {
      overflow: hidden; }
      .properties-list-page .property-info-group .views-field-field-lot-area .views-label {
        display: inline;
        margin-right: 3px;
        font-weight: 600; }
      .properties-list-page .property-info-group .views-field-field-lot-area .field-content {
        display: inline; }
    .properties-list-page .property-info-group .views-field-field-bedrooms, .properties-list-page .property-info-group .property-bedroom-icon,
    .properties-list-page .property-info-group .views-field-field-bathrooms, .properties-list-page .property-info-group .property-bathroom-icon {
      float: right;
      margin-right: 0;
      margin-left: 15px;
      padding-left: 25px;
      position: relative; }
      .properties-list-page .property-info-group .views-field-field-bedrooms:before, .properties-list-page .property-info-group .property-bedroom-icon:before,
      .properties-list-page .property-info-group .views-field-field-bathrooms:before, .properties-list-page .property-info-group .property-bathroom-icon:before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 18px;
        height: 15px;
        overflow: hidden;
        background-image: url("../images/sprite.png");
        background-repeat: no-repeat; }
    .properties-list-page .property-info-group .views-field-field-bathrooms:before, .properties-list-page .property-info-group .property-bathroom-icon:before {
      background-position: 0 -112px; }
    .properties-list-page .property-info-group .views-field-field-bedrooms:before, .properties-list-page .property-info-group .property-bedroom-icon:before {
      background-position: 0 -94px; }

/*pager*/
.content .pager {
  margin-top: 40px;
  margin-bottom: 0;
  text-align: left; }
  .content .pager li {
    padding: 0;
    display: inline-block;
    float: left;
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 8px; }
  .content .pager a {
    display: inline-block;
    border: 2px solid #2D2D2D;
    padding: 15px 20px;
    text-transform: uppercase;
    color: #333;
    position: relative;
    z-index: 2;
    border-radius: 0;
    -webkit-border-radius: 0;
    padding: 11px 15px;
    line-height: 1em;
    background: none;
    z-index: 2;
    min-width: 40px;
    text-transform: capitalize;
    font-weight: 600; }
    .content .pager a:after {
      height: 100%;
      left: 0;
      top: 0;
      width: 0;
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
    .content .pager a:hover, .content .pager a:focus, .content .pager a:active {
      color: #fff; }
      .content .pager a:hover:after, .content .pager a:focus:after, .content .pager a:active:after {
        width: 100%;
        background: #2D2D2D; }
  .content .pager .pager-current {
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: #333;
    text-align: center;
    padding-top: 9px; }

/* featured properties 2 */
.featured-properties-2 .jcarousel-container {
  height: auto; }
  .featured-properties-2 .jcarousel-container .jcarousel-clip-horizontal {
    height: auto; }
  .featured-properties-2 .jcarousel-container .jcarousel-item {
    padding: 20px;
    width: 555px !important;
    background-color: #fff;
    margin-right: 30px; }
  .featured-properties-2 .jcarousel-container .property-image-group {
    width: 280px; }
  .featured-properties-2 .jcarousel-container .views-field-title {
    text-transform: inherit; }

/* skin menu */
.change-skin-menu-wrapper {
  position: fixed;
  left: -188px;
  top: 150px;
  z-index: 500;
  background-color: #fafafa;
  box-shadow: 1px 1px 2px -1px #888;
  -webkit-box-shadow: 1px 1px 2px -1px #888;
  border-radius: 0 0 3px 0;
  -webkit-border-radius: 0 0 3px 0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  /* tag a */
  /* color */
  /*background*/
  /*layout*/
  /* color for skin */ }
  .change-skin-menu-wrapper .container {
    width: 185px;
    padding: 10px; }
  .change-skin-menu-wrapper .background,
  .change-skin-menu-wrapper .skin-color {
    overflow: hidden; }
  .change-skin-menu-wrapper .skin-color {
    margin-bottom: 15px; }
  .change-skin-menu-wrapper .change-skin {
    text-align: center;
    border-radius: 0 5px 5px 0;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: #fafafa;
    padding: 6px;
    width: 33px;
    height: 30px;
    box-shadow: 1px 1px 2px -2px #888;
    -webkit-box-shadow: 1px 1px 2px -2px #888;
    color: #666;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; }
    .change-skin-menu-wrapper .change-skin:hover, .change-skin-menu-wrapper .change-skin:focus {
      color: #333; }
    .change-skin-menu-wrapper .change-skin i {
      font-size: 18px; }
  .change-skin-menu-wrapper strong {
    margin-bottom: 10px;
    display: block;
    font-weight: 600; }
  .change-skin-menu-wrapper ul {
    padding-left: 0;
    margin-bottom: 0; }
  .change-skin-menu-wrapper li {
    list-style: none;
    display: inline-block;
    float: left;
    margin-right: 3px;
    margin-bottom: 3px; }
  .change-skin-menu-wrapper .change-skin-button,
  .change-skin-menu-wrapper .change-background-button {
    display: inline-block;
    text-indent: -999em;
    width: 30px;
    height: 30px;
    opacity: 0.7; }
    .change-skin-menu-wrapper .change-skin-button:hover, .change-skin-menu-wrapper .change-skin-button:focus,
    .change-skin-menu-wrapper .change-background-button:hover,
    .change-skin-menu-wrapper .change-background-button:focus {
      opacity: 1; }
  .change-skin-menu-wrapper .change-background-button {
    border: 1px solid #cdcdcd; }
    .change-skin-menu-wrapper .change-background-button:hover, .change-skin-menu-wrapper .change-background-button:focus {
      border-color: #7b7b7b; }
  .change-skin-menu-wrapper .layout {
    margin-bottom: 15px; }
  .change-skin-menu-wrapper .change-layout-button {
    border-radius: 0;
    -webkit-border-radius: 0;
    padding: 4px 18px;
    background-color: #fff;
    background-image: none; }
    .change-skin-menu-wrapper .change-layout-button:first-child {
      border-right: none; }
  .change-skin-menu-wrapper .change-layout-button.active {
    background-color: #428bca;
    color: #fff;
    border-color: #428bca; }
  .change-skin-menu-wrapper .color-default {
    background-color: #e52629; }
  .change-skin-menu-wrapper .color-blue {
    background-color: #3366cc; }
  .change-skin-menu-wrapper .color-green {
    background-color: #8cc474; }
  .change-skin-menu-wrapper .color-orange {
    background-color: #f9b256; }
  .change-skin-menu-wrapper .color-cyan {
    background-color: #4dbfd9; }
  .change-skin-menu-wrapper .color-violet {
    background-color: #cb84ce; }

.fly-out {
  left: 0; }

/* Clickable divs */
.clickable:hover {
  cursor: pointer; }
